import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { currentApi } from '../../services/Db/dbInfo';
import { UserResponse } from '../../services/User/user';
import storage from '../../utils/storage';
import { Spinner } from '../Spinner/Spinner';

// additonal handling for social media registered as Guide.
function handleUserResponse(data: UserResponse) {
  const { jwt, user } = data;
  const socialMediaLogin = storage.isSocialMediaLogin();
  storage.setToken(jwt);
  if(socialMediaLogin) {
    storage.addRegisterAsGuide(user.email);
  }
  storage.clearSocialMediaLogin();
  return user;
}

const LoginRedirect = (props: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${currentApi.baseUriValue}/auth/${params.providerName}/callback${location.search}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`${t('notification_messages.error')}: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        handleUserResponse(res);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);

        NotificationManager.error(t('notification_messages.error'));
        navigate('/signin');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, navigate, params.providerName]);

  return (
    <>
      {' '}
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image register-bg-image1"></div>
          <div className="col-md-8 col-lg-6">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    <Spinner />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginRedirect;
