import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ButtonSpinner } from '../../../components/Spinner/ButtonSpinner';
import { useContainerContext } from '../../../context/ContainerContext';
import moment from '../../../lib/moment';
import { useGetGuideUnreadMessageCount } from '../../../services/Message/messageService';

// core components
function Dashboard() {
  const { t } = useTranslation();
  const { state } = useContainerContext();
  const { data, isLoading: isMessageCountLoading } = useGetGuideUnreadMessageCount(state.guideId);
  if (isMessageCountLoading) return <ButtonSpinner />;
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="4" md="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-email-85 text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">{t('conversations.unread_messages')}</p>
                      <Card.Title as="p">
                        {isMessageCountLoading ? <ButtonSpinner /> : data?.count}
                      </Card.Title>
                      <p />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr />
                {data?.lastMessageDate && (
                  <div className="stats">
                    <i className="far fa-clock" />{' '}
                    {t('common.last_time')} {`${moment(data?.lastMessageDate)
                      .toNow(true)
                      .replace('a few', '')}`}
                  </div>
                )}
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="4" md="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col md="12" xs="12">
                    <Card.Title as="h5">{t('common.user_visits')}</Card.Title>
                    <p className="card-category">24 {t('common.hours_performance')}</p>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr />
                <div className="stats">
                  <i className="fa fa-history" />
                  {t('common.updated_time_ago')}
                </div>
              </Card.Footer>
            </Card>
          </Col>

          <Col lg="4" md="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">{t('common.followers')}</p>
                      <Card.Title as="p">0</Card.Title>
                      <p />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr />
                <div className="stats">
                  <i className="far fa-refresh"></i>
                  {t('common.update_now')}
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
