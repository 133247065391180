import { useEffect } from 'react';
import { useUser } from '../../lib/auth';
import storage from '../../utils/storage';
import GuideDashboard from './components/GuideDashboard';
import UserDashboard from './components/UserDashboard';
import { useLocation, useNavigate } from 'react-router-dom';

function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: userInfo } = useUser();
  const isUserDisplayMode = storage.isUserDisplayMode();
  const isGuide = (userInfo?.isGuide && !isUserDisplayMode) || false;
  const pendingReview = storage.getPendingReview();
  const registeredAsGuide = storage.getRegisterAsGuide();

  useEffect(()=> {
    if(pendingReview && pendingReview.redirectUrl){
      window.location.href = pendingReview.redirectUrl;
    } else if(registeredAsGuide && registeredAsGuide === userInfo?.email){
      storage.clearRegisterAsGuide();
      navigate('/guides');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingReview, registeredAsGuide, location.pathname]);

  return <>{isGuide ? <GuideDashboard /> : <UserDashboard />}</>;
}

export default Dashboard;
