/* eslint-disable react-hooks/exhaustive-deps */
import * as _ from 'lodash';
import React, { lazy, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  AsyncSelect,
  CurrencyInputField,
  DateField,
  FileSelectionField,
  MultiCheckBoxField,
  MultiSelectField,
  RadioField,
  SelectField,
  StaticDataSelectField,
  TextField,
} from '../../../../components/Form';
import { Option } from '../../../../components/Form/props';

import { PresentYourBoat, TripDetails } from '../../../../services/Guide/guide';

import i18next from 'i18next';
import { ButtonSpinner } from '../../../../components/Spinner/ButtonSpinner';
import useOptionData from '../../../../lib/optionData';
import useTripHook from '../../../../services/Trip/tripService';
import { genericApi } from '../../../../services/genericApi';
import useGuideOptionsHook from '../../../../services/guideOptionsHook';
import { dirtyValues, isEmpty, scrollToTop } from '../../../../services/utils';
const CKEditorComponent = lazy(() => import('../../../../components/CKEditorComponent'));

interface InputProps {
  index: number;
  name: string;
  edit: boolean;
  onSave: any;
  onCancel: any;
}

export const FormTrip: React.FC<InputProps> = (props: any) => {
  const { t } = useTranslation();
  const {
    hours,
    dayCancelations,
    departureTime,
    numberofGuests,
    minAgeOptions,
    tripTypesOptions,
    radioFieldOptions,
    alcoholPolicyOptions,
    daysOptions,
  } = useOptionData();
  const {
    useFishingTechniques: fishingTechniques,
    useFishingTypes: typeOfFishing,
    useFishingSpecies: fishingSpecies,
    useFoodAndBeverages: foodAndBeveragesIncluded,
    useCatchPolicies: catchPolicy,
    usePickupPolicies: pickupPolicy,
    useCancellationPolicies: cancellationPolicyOptions,
  } = useGuideOptionsHook();

  const [cityDetail, setCityDetail] = useState<Place>();

  const fieldName = `${props.name}[${props.index}]`;

  const backHandler = () => {
    const data = getValues(fieldName);
    props.onCancel(props.index, data.id);
    scrollToTop();
  };
  const { trigger, getFieldState, watch, setValue, getValues, formState } = useFormContext();
  const formValues = watch(fieldName);
  const boats = watch('presentYourBoats') || [];
  const guideBoats = _.map(boats, (value: PresentYourBoat) => ({
    label: `${value.boatType?.label} (${value.boatLength}ft)`,
    value: (value.id || 0).toString(),
  }));

  const { postTrip, updateTrip } = useTripHook({});
  const { mutateAsync: mutateAsyncPost, isLoading: isLoadingPost } = postTrip;
  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdate } = updateTrip;
  const [hasScrolledToError, setHasScrolledToError] = useState(false);

  const submitHandler = () => {
    trigger(fieldName).then(() => {
      const { error } = getFieldState(fieldName);
      if (error) {
        if (!hasScrolledToError) {
          const errorField = document.querySelector(`[name="${fieldName}.${Object.keys(error)[0]}"]`);
          if (errorField) errorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setHasScrolledToError(true);
        }
      } else {
        const tripDetail: TripDetails = getValues(fieldName);
        if (!isEmpty(formState.dirtyFields)) {
          if (tripDetail.id) {
            let dirtyFields = dirtyValues(
              formState?.dirtyFields?.presentYourTrips[props.index],
              tripDetail
            );
            if (Object.keys(dirtyFields).length) {
              dirtyFields.id = tripDetail.id;
              mutateAsyncUpdate(dirtyFields).then(() => {
                props.onSave();
              });
            } else {
              props.onSave();
            }
          } else {
            const guideDetail = getValues();
            if (guideDetail.id) {
              tripDetail.guide = guideDetail.id;
              mutateAsyncPost(tripDetail).then(() => {
                props.onSave();
              });
            }
          }
        } else {
          props.onSave();
        }
        setHasScrolledToError(false);
      }
    });
  };
  const loadCityOptions = useCallback(
    (value: string) => {
      const countryCode = formValues.country;
      return new Promise<Option[]>((resolve) => {
        const data = genericApi
          .getCityByCountryMapBoxLocation(value, countryCode)
          .then((result) => {
            return _.map(result, (obj: any) => ({
              label: obj[`place_name_` + i18next.language],
              value: obj.text,
              obj: obj,
            }));
          });
        resolve(data);
      });
    },
    [formValues.country, i18next.language]
  );

  const onCityChange = (option: Option) => {
    setCityDetail(option.obj);
    setValue(`${fieldName}.streetAddress`, '', { shouldDirty: true });
    setValue(`${fieldName}.postalCode`, '', {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (formValues.city) {
        try {
          const cityOptions = await loadCityOptions(formValues.city);
          if (cityOptions && cityOptions.length > 0) {
            setCityDetail(cityOptions[0].obj);
          }
        } catch (error) {
          console.error('Error fetching address options:', error);
        }
      }
    };

    fetchData();
  }, [formValues.city]);

  const loadAddressOptions = useCallback(
    (value: string) => {
      const countryCode = formValues.country;
      const center: any = cityDetail?.center;

      return new Promise<Option[]>((resolve) => {
        if (cityDetail) {
          const addressbyCityCountryMapBoxLocation = genericApi
            .getAddressbyCityCountryMapBoxLocation(value, countryCode, cityDetail?.bbox, center)
            .then((result) => {
              return _.map(result, (obj: any) => ({
                label: obj[`place_name_` + i18next.language],
                value: obj.place_name,
                obj: obj,
              }));
            });
          resolve(addressbyCityCountryMapBoxLocation);
        } else {
          resolve([]);
        }
      });
    },
    [cityDetail, formValues.country, i18next.language]
  );

  const onAddressChange = (option: Option) => {
    if (option.obj) {
      const place = option.obj;
      setValue(`${fieldName}.long`, place.center[0], { shouldDirty: true });
      setValue(`${fieldName}.lat`, place.center[1], { shouldDirty: true });
      const postcodeContext = place.context.find((item: { id: string | string[] }) =>
        item.id.includes('postcode')
      );
      const postalCode = postcodeContext ? postcodeContext.text : null;
      setValue(`${fieldName}.postalCode`, postalCode, {
        shouldDirty: true,
      });
    }
  };

  const onCountryChange = (option: Option) => {
    setValue(`${fieldName}.city`, '', { shouldDirty: true });
  };

  const loadCountryOptions = React.useCallback(
    (value: string) => {
      return new Promise<Option[]>((resolve) => {
        const data = genericApi.getCountryMapBoxLocation(value).then((result) => {
          return _.map(result, (obj: any) => ({
            label: obj[`place_name_` + i18next.language],
            value: obj?.properties?.short_code,
            obj: obj,
          }));
        });
        resolve(data);
      });
    },
    [i18next.language]
  );

  return (
    <div id="collapseInputTrip" key={fieldName}>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <div className="mt-2 mb-3">
            <h5>{t('trip.title')}</h5>
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <RadioField
            name={`${fieldName}.type`}
            label={t('trip.fields.type.label')}
            options={tripTypesOptions}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <TextField name={`${fieldName}.name`} placeholder={t('trip.fields.name.placeholder')} />
        </div>
      </div>
      <div className="row">
        <CKEditorComponent
          name={`${fieldName}.description`}
          placeholder={t('trip.fields.description.placeholder')}
        />
      </div>
      <div className="row mt-3">
        <div className="form-group col-md-6">
          <StaticDataSelectField
            className="product_select"
            name={`${fieldName}.duration`}
            placeholder={t('trip.fields.duration.placeholder')}
            options={hours}
          />
        </div>
        <div className="form-group col-md-6">
          <StaticDataSelectField
            className="product_select"
            name={`${fieldName}.departure`}
            placeholder={t('trip.fields.departure.placeholder')}
            options={departureTime}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col-md-6">
          <StaticDataSelectField
            className="product_select"
            name={`${fieldName}.maxAmountofPeople`}
            placeholder={t('trip.fields.maxAmountofPeople.placeholder')}
            options={numberofGuests}
          />
        </div>
        <div className="form-group col-md-6">
          <StaticDataSelectField
            className="product_select"
            name={`${fieldName}.minimumAge`}
            placeholder={t('trip.fields.minimumAge.placeholder')}
            options={minAgeOptions}
          />
        </div>
      </div>
      <div className="row">
        <FileSelectionField
          name={`${fieldName}.tripMedia`}
          label={t('trip.fields.tripMedia.label')}
          maxNumber={12}
          selectedImagePath={'TempTripPic'}
        />
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <RadioField
            name={`${fieldName}.isSharedTrip`}
            label={t('trip.fields.isSharedTrip.label')}
            options={radioFieldOptions}
          />
        </div>
      </div>
      {formValues.isSharedTrip?.toString() === 'true' && (
        <>
          <div className="row">
            <CurrencyInputField
              name={`${fieldName}.pricePerPerson`}
              placeholder={t('trip.fields.pricePerPerson.placeholder')}
            />
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <StaticDataSelectField
                className="product_select"
                name={`${fieldName}.minAmountofPeople`}
                placeholder={t('trip.fields.minAmountofPeople.placeholder')}
                options={numberofGuests}
              />
            </div>
          </div>
        </>
      )}
      {formValues.isSharedTrip?.toString() === 'false' && (
        <div className="row">
          <CurrencyInputField
            name={`${fieldName}.priceifNotShared`}
            placeholder={t('trip.fields.priceifNotShared.placeholder')}
          />
        </div>
      )}
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <div className="mt-4">
            <h5>{t('common.session_availibility')}</h5>
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className=" col-md-12">
          <RadioField
            name={`${fieldName}.isSeasonalTrip`}
            label={t('trip.fields.isSeasonalTrip.label')}
            options={radioFieldOptions}
          />
        </div>
      </div>
      <div
        className="row"
        style={{
          display: formValues.isSeasonalTrip?.toString() === 'true' ? 'inherit' : 'none',
        }}
      >
        <div className="form-group col-md-6">
          <div className="visible-xs visible-sm row-space-top-2"></div>
          <div style={{ position: 'relative' }}>
            <div className="searchpage_input2">
              <DateField
                name={`${fieldName}.season.from`}
                placeholder={t('trip.fields.session_form.placeholder')}
              />
            </div>
          </div>
        </div>
        <div className="form-group col-md-6">
          <div className="visible-xs visible-sm row-space-top-2"></div>
          <div style={{ position: 'relative' }}>
            <div className="searchpage_input2">
              <DateField
                name={`${fieldName}.season.to`}
                placeholder={t('trip.fields.session_to.placeholder')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <MultiCheckBoxField
          name={`${fieldName}.operateDays`}
          label={t('trip.fields.operateDays.label')}
          options={daysOptions}
        />
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <div className="mt-4">
            <h5>{t('trip.fishing_specifics')}</h5>
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <MultiSelectField
            className="product_select"
            name={`${fieldName}.targetedSpecies`}
            placeholder={t('trip.fields.targetedSpecies.placeholder')}
            label={t('trip.fields.targetedSpecies.label')}
            options={fishingSpecies}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <MultiSelectField
            className="product_select"
            name={`${fieldName}.fishingTechniques`}
            placeholder={t('trip.fields.fishingTechniques.placeholder')}
            label={t('trip.fields.fishingTechniques.label')}
            options={fishingTechniques}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <MultiSelectField
            className="product_select"
            name={`${fieldName}.fishingTypes`}
            placeholder={t('trip.fields.fishingTypes.placeholder')}
            label={t('trip.fields.fishingTypes.label')}
            options={typeOfFishing}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="form-group col-md-12">
          <div className="mt-4">
            <h5>{t('trip.trip_features')}</h5>
          </div>
        </div>
      </div>
      <div className="row">
        <div className=" col-md-12">
          <RadioField
            name={`${fieldName}.isNecessaryGearIncluded`}
            label={t('trip.fields.isNecessaryGearIncluded.label')}
            options={radioFieldOptions}
          />
        </div>
      </div>
      <div className="row">
        <div className=" col-md-12">
          <RadioField
            name={`${fieldName}.isFishingLicenseIncluded`}
            label={t('trip.fields.isFishingLicenseIncluded.label')}
            options={radioFieldOptions}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <RadioField
            name={`${fieldName}.alcoholAllowed`}
            label={t('trip.fields.alcoholAllowed.label')}
            options={alcoholPolicyOptions}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-12">
          <RadioField
            name={`${fieldName}.multiBoatTrip`}
            label={t('trip.fields.multiBoatTrip.label')}
            options={radioFieldOptions}
          />
        </div>
      </div>
      <div className="row">
        <MultiCheckBoxField
          name={`${fieldName}.foodAndBeverages`}
          label={t('trip.fields.foodAndBeverages.label')}
          options={foodAndBeveragesIncluded}
        />
      </div>
      <div className="row mt-1">
        <div className="form-group col-md-12">
          <MultiSelectField
            className="product_select"
            name={`${fieldName}.catchPolicy`}
            label={t('trip.fields.catchPolicy.label')}
            placeholder={t('trip.fields.catchPolicy.placeholder')}
            options={catchPolicy}
          />
        </div>
      </div>

      {guideBoats && guideBoats.length > 0 && (
        <div className="row">
          <div className="form-group col-md-12">
            <p>{t('trip.trip_Boat').toString()}</p>
            <SelectField
              className="product_select"
              name={`${fieldName}.guide_boat`}
              placeholder={t('trip.fields.guide_boat.placeholder')}
              options={guideBoats}
            />
          </div>
        </div>
      )}
      <div className="row mt-4">
        <div className="form-group col-md-12">
          <div className="mt-4">
            <h5>{t('trip.meeting_point')}</h5>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="form-group col-md-6">
          <AsyncSelect
            className="product_select"
            name={`${fieldName}.country`}
            placeholder={t('trip.fields.country.placeholder')}
            loadOptions={loadCountryOptions}
            onChange={onCountryChange}
          />
        </div>

        <div className="form-group col-md-6">
          <AsyncSelect
            className="product_select"
            name={`${fieldName}.city`}
            placeholder={t('trip.fields.city.placeholder')}
            loadOptions={loadCityOptions}
            onChange={onCityChange}
            disabled={!formValues.country}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="form-group col-md-6">
          <AsyncSelect
            className="product_select"
            name={`${fieldName}.streetAddress`}
            placeholder={t('trip.fields.streetAddress.placeholder')}
            loadOptions={loadAddressOptions}
            disabled={!formValues.city}
            onChange={onAddressChange}
          />
        </div>
        <div className="form-group col-md-6">
          <TextField
            name={`${fieldName}.postalCode`}
            placeholder={t('trip.fields.postalCode.placeholder')}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <SelectField
            className="product_select"
            name={`${fieldName}.pickupPolicy`}
            placeholder={t('trip.fields.pickupPolicy.placeholder')}
            options={pickupPolicy}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="form-group col-md-12">
          <div className="mt-4">
            <h5>{t('trip.cancellation')}</h5>
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className=" col-md-12">
          <RadioField
            name={`${fieldName}.cancellationPolicy`}
            label={t('trip.fields.cancellationPolicy.label')}
            options={cancellationPolicyOptions}
          />
        </div>
      </div>

      {formValues.cancellationPolicy?.toString() === '2' && (
        <div className="form-row">
          <div className="form-group col-md-12">
            <StaticDataSelectField
              name={`${fieldName}.freeCancellation`}
              placeholder={t('trip.fields.freeCancellation.placeholder')}
              options={_.map(dayCancelations, (policy: number) => ({
                label: `${policy.toString()} ${t('common.days')}`,
                value: policy.toString(),
              }))}
            />
          </div>
        </div>
      )}

      <div className="row mt-4">
        <div
          className="form-group col-md-12"
          style={{ borderBottom: '1px solid rgba(0,0,0,.125)' }}
        >
          <div className="mt-2 mb-3">
            <button type="button" className="btn previous previous_button" onClick={backHandler}>
              {t('common.cancel')}
            </button>
            <button
              type="button"
              className="next btn btn-gold"
              onClick={submitHandler}
              disabled={isLoadingPost || isLoadingUpdate}
            >
              {isLoadingPost || isLoadingUpdate ? (
                <ButtonSpinner message={t('common.saving')} />
              ) : (
                t('common.save')
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
