import {
  PaginationState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { Dispatch, Fragment, SetStateAction, useMemo } from 'react';
import { Pagination, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ButtonSpinner } from '../Spinner/ButtonSpinner';
import './style.css';

interface DataTableProps {
  dataQuery: any;
  tableColumns: any;
  pagination: PaginationState;
  onPaginationChange: Dispatch<SetStateAction<PaginationState>>;
  getRowCanExpand?: any;
  RenderRowSubComponent?: any;
  expanded?: any;
}

const DataTable = React.memo(
  ({
    dataQuery,
    tableColumns,
    pagination,
    onPaginationChange,
    getRowCanExpand,
    RenderRowSubComponent,
    expanded = undefined,
  }: //  onSortingChange,
  DataTableProps) => {
    const { t } = useTranslation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo(() => tableColumns, []);
    const defaultData = useMemo(() => [], []);
    let state: any = { pagination };
    if (expanded && expanded.id && dataQuery?.data?.data) {
      const data = dataQuery?.data?.data;
      let index = data?.findIndex((obj: any) => Number(obj.id) === expanded.id);
      if (index >= 0) {
        state.expanded = { [index]: true };
      }
    }

    const table = useReactTable({
      data: dataQuery?.data?.data ?? defaultData,
      pageCount: dataQuery?.data?.meta?.pagination?.pageCount ?? -1,
      columns,
      state: state,
      //onSortingChange,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getRowCanExpand,
      getExpandedRowModel: getExpandedRowModel(),
      onPaginationChange,
      debugTable: false,
      manualPagination: true,
    });

    return (
      <div className="dataTables_wrapper form-inline">
        <div className="row">
          <div className="col-sm-12">
            <div className="pull-right">
              <div className="dataTables_filter">
                <label></label>
              </div>
            </div>
            <div style={{ float: 'left' }}>
              <div className="dataTables_length">
                {t('common.show')}
                <select
                style={{ marginLeft: '5px' }}
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>{' '}
                {t('common.entries')}
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
        <Table responsive>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {dataQuery?.data?.data &&
              table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <tr>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <tr>
                      <td colSpan={row.getVisibleCells().length}>
                        <RenderRowSubComponent row={row?.original} key={row?.original} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            {table.getRowModel().rows.length === 0 && (
              <tr>
                <td
                  colSpan={table?.getHeaderGroups()[0]?.headers?.length}
                  style={{ textAlign: 'center' }}
                >
                  {dataQuery?.isLoading && (
                    <span>
                      <ButtonSpinner />
                    </span>
                  )}
                  {!dataQuery?.isLoading && <span> {t('common.no_data_available')}</span>}
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="row">
          <div className="col-sm-12">
            <div style={{ float: 'left' }}>
              <div className="dataTables_info">
                {t('common.showing_page')
                  .toString()
                  .replace('{{pageIndex}}', (table.getState().pagination.pageIndex + 1).toString())
                  .replace('{{pageCount}}', table.getPageCount().toString())}
              </div>
            </div>
            <div style={{ float: 'right' }}>
              <div className="dataTables_paginate paging_bs_normal">
                {/* Pagination */}

                <Pagination>
                  <Pagination.Item
                    onClick={() => {
                      table.setPageIndex(0);
                    }}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {t('common.first')}
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      table.previousPage();
                    }}
                    disabled={!table.getCanPreviousPage()}
                  >
                    {t('common.previous')}
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => {
                      table.nextPage();
                    }}
                    disabled={!table.getCanNextPage()}
                  >
                    {t('common.next')}
                  </Pagination.Item>
                  <Pagination.Item
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                    disabled={!table.getCanNextPage()}
                  >
                    {t('common.last')}
                  </Pagination.Item>
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default DataTable;
